import { mapState } from 'vuex'
import { getCustomerConfig } from 'base/api'
import { trackEvent } from 'base/utils/rp'

export default {
  name: 'customService',
  // components: { IconFont, Distribute, BottomButton },
  data() {
    return {
      isSdkReady: false,
      clickedBeforeReady: false,
      customerType: 1,
      isInAliPayMp: false,
      showDistribute: true
    }
  },

  computed: {
    ...mapState({
      isInAliPay: (state) => state.appState.isInAliPay,
      appType: (state) => state.appState.appType,
      phone: (state) => state.user.phone
    })
  },

  created() {
    const { ...params } = this.$route.query
    this.customParams = {
      real_name: this.phone,
      phone: this.phone,
      receiverName: this.cookie.get('receiverName'),
      api: process.env.VUE_APP_BASE_API,
      userId: this.$store.state.user.userId,
      appType: this.appType,
      address: [
        this.cookie.get('addressProvince'),
        this.cookie.get('addressCity'),
        this.cookie.get('addressArea')
      ].join('$'),

      ...params
    }
    this.loadCustomerService()
    if (localStorage.getItem('hideGuessfavor')) {
      this.showDistribute = false
    } else {
      this.showDistribute = true
    }
  },

  destroyed() {
    if (window.ysf) {
      window.ysf('logoff')
    }
  },

  methods: {
    onClickArea(name, action) {
      trackEvent({
        category: `点击-客服页面-${name}`,
        action: action
      })
    },

    kefu() {
      // console.info('拉起客服')
      // window.location.href = "http://yanxuan618.com/kefu.htm";
      this.onClickArea('在线客服')
      if (this.customerType === 1) {
        if (this.isSdkReady) {
          // http://qiyukf.com/docs/guide/web/4-%E8%87%AA%E5%AE%9A%E4%B9%89%E6%A0%B7%E5%BC%8F.html#%E5%85%A5%E5%8F%A3%E8%87%AA%E5%AE%9A%E4%B9%89
          // https://tpyx.qiyukf.com/admin/setting/visitor
          console.log('templateId', this.renderBrandElement({ id: 'ysf.templateId' }))
          window.ysf('open', {
            templateId: this.renderBrandElement({ id: 'ysf.templateId' })
          })

          // console.info('拉起客服，已初始化', document.getElementById('YSF-BTN-HOLDER'))
          document.getElementById('YSF-BTN-HOLDER').click()
        } else {
          // console.info('拉起客服，未初始化')
          // Toast.fail('客服忙碌中\n请稍后再试');
          this.clickedBeforeReady = true
        }
      }
    },

    async loadCustomerService() {
      const resp = await getCustomerConfig()
      if (resp.data.type === '2') {
        this.customerType = 2
      }

      const $this = this
      // eslint-disable-next-line no-unused-vars
      const groupId = resp.data?.groupId || this.renderBrandElement({ id: 'ysf.groupId' })
      const welcomeTemplateId = resp.data?.welcomeTemplateId || this.renderBrandElement({ id: 'ysf.welcomeTemplateId' })
      // type'1'是七鱼客服 '2'是53客服
      if (resp.data?.type === '1') {
        if (!$this.isSdkReady) {
          // eslint-disable-next-line prettier/prettier
          (function (w, d, n, a, j) {
            w[n] =
              w[n] ||
              function () {
                // eslint-disable-next-line prettier/prettier
                (w[n].a = w[n].a || []).push(arguments)
              }
            j = d.createElement('script')
            j.id = 'qiyu'
            j.async = true
            j.src = resp.data.url
            d.body.appendChild(j)
          })(window, document, 'ysf')
          // 此处为啥直接就可以用window.ysf?
          window.ysf('onready', function () {
            const params = $this.transToKeyValueArray({
              ...$this.customParams
            })
            console.log('qiyuParams', params)
            const obj = {
              uid: $this.$store.state.user.userId,
              robotShuntSwitch: 1,
              // groupid: groupId, // 客服id
              welcomeTemplateId, // 欢迎语模板
              data: JSON.stringify(params),
              success: () => {
                $this.isSdkReady = true
                if ($this.clickedBeforeReady) {
                  $this.kefu()
                  $this.clickedBeforeReady = false
                }
              }
            }
            console.log('obj', obj)
            window.ysf('config', obj)
          })
        }
      } else {
        // eslint-disable-next-line prettier/prettier
        (function () {
          const scriptElement = document.createElement('script')
          scriptElement.src = resp.data.url
          const s = document.getElementsByTagName('script')[0]
          s.parentNode.insertBefore(scriptElement, s)
        })()
      }
    },

    toHome() {
      this.onClickArea('返回jf页')
      this.$router.push({ path: '/home' })
      // TODO: 如果用户把此页面直接复制给好友，那么好友点击返回时，由于 history 没有历史，就会直接退出网页。
      // 但是 history.length 并不准确，暂时不处理，再寻找更好的解决方案。
      // 所有的go(-1)都有这个问题。
      // https://blog.csdn.net/yuxuemu/article/details/77801558
      // this.$router.go(-1)
    },

    transToKeyValueArray(obj) {
      // appType
      // goodsId
      // userId
      // api
      // location
      // phone
      // receiverName
      // address
      const tempArr = []
      for (const key in obj) {
        // "crm_param"类型的参数透传给iframe页面
        tempArr.push({ type: 'crm_param', key, value: obj[key] })
      }
      console.log('tempArr', tempArr)
      return tempArr
    },

    toComplain() {
      this.$router.push({ path: '/complain' })
    }
  }
}
