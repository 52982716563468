import 'intersection-observer'
import { trackUserEvent, webfunnyTrack } from './rp'
import { checkJson } from './index'
import xEnum from 'base/utils/enum'
import { sendQuickTrackingEvent, EventType } from 'base/utils/quickTracking'
import store from '@/store'

// 节流时间调整，默认100ms
IntersectionObserver.prototype['THROTTLE_TIMEOUT'] = 300

/** 判断是否有足够的区域在视窗内
 * @param {ElementRect} el
 * @param {ElementRect} root
 * @param {Number} threshold
 * */
function isInViewPort(el, root, threshold) {
  let viewHeight = el.height
  if (el.bottom < el.height) {
    viewHeight = el.bottom
  } else if (root.height - el.top < el.height) {
    viewHeight = root.height - el.top
  }
  let viewWidth = el.width
  if (el.right < el.width) {
    viewWidth = el.right
  } else if (root.width - el.left < el.width) {
    viewWidth = root.width - el.left
  }
  const ratio = (viewWidth * viewHeight) / (el.width * el.height)
  return ratio >= threshold
}

export default class Exposure {
  constructor() {
    this.cacheDataArr = []
    this.maxNum = 20
    this._timer = 0
    this._observer = null
    this.init()
  }

  /**
   * 初始化
   */
  init() {
    let buryPointConfig = {
      exposeDuration: 300,
      exposeMinAreaRate: 0.8,
      pushInterval: 2000,
      queueSize: 20,
      eventTypes: [Number(xEnum.ENUM_RP_EVENT_TYPE.ROUTE_TO_PAGE.value)]
    }
    if (checkJson(window.localStorage.getItem('buryPointConfig'))) {
      buryPointConfig = JSON.parse(window.localStorage.getItem('buryPointConfig'))
    }
    const config = buryPointConfig
    this.maxNum = config.queueSize
    const self = this
    // 边界处理
    this.trackFromLocalStorage()
    this.beforeLeaveWebview()

    // 实例化监听
    this._observer = new IntersectionObserver(
      // eslint-disable-next-line no-unused-vars
      function (entries, observer) {
        entries.length &&
          entries.forEach(async (entry) => {
            // 出现在视窗中
            if (entry && entry.isIntersecting) {
              // 清除当前定时器
              self._timer && clearInterval(self._timer)
              // 暴露最少时长 默认0ms
              const exposureTime = config.exposeDuration
              // 是否暴露了最少时长，用定时器判断 最少时长后的时刻状态
              if (entry.target.entryTimer) {
                clearTimeout(entry.target.entryTimer)
              }
              let isEnoughTime = true
              if (exposureTime) {
                // 创建promise等待判断是否有足够内容在视窗内
                // eslint-disable-next-line no-unused-vars
                isEnoughTime = await new Promise((resolve, reject) => {
                  entry.target.entryTimer = setTimeout(() => {
                    if (
                      isInViewPort(entry.target.getBoundingClientRect(), entry.rootBounds, config.exposeMinAreaRate)
                    ) {
                      if (!store.state.buryState.exposureDomSet.has(entry.target)) {
                        store.commit('SET_EXPOSURE_DOM_SET', entry.target)

                        const { eventType, eventInfo } = JSON.parse(entry.target.attributes['track-params'].value)
                        if (eventType === '49') {
                          //顺手买商品曝光重复问题
                          const goodsInfo = `${eventInfo.goodsId}_${eventInfo.goodsSpecId}`
                          const hasItem = store.state.buryState.exposureLimitForMorePurchaseCard.includes(goodsInfo)
                          if (hasItem) return resolve(false)
                          store.commit('SET_MORE_PURCHASE_ITEM', goodsInfo)
                        }

                        resolve(true)
                      } else {
                        resolve(false)
                      }
                    } else {
                      resolve(false)
                    }
                  }, exposureTime)
                })
              }
              // 满足暴露时长/未设置暴露时长 塞入待上报队列
              if (isEnoughTime) {
                const tp = Object.assign(JSON.parse(entry.target.attributes['track-params'].value), {
                  timestamp: new Date().getTime()
                })
                // 收集参数统一上报，减少网络请求
                self.cacheDataArr.push(tp)
                // 曝光之后取消观察
                if (self.cacheDataArr.length > self.maxNum) {
                  self.track()
                } else {
                  self.storeIntoLocalStorage(self.cacheDataArr)
                  if (self.cacheDataArr.length > 0) {
                    // 2秒上报一次
                    self._timer = setInterval(function () {
                      self.track()
                    }, config.pushInterval)
                  }
                }
              } else {
                // 不满足暴露时长
                const cacheEle = entry.target
                self._observer.unobserve(entry.target)
                self.add({ el: cacheEle })
              }
            }
          })
      },
      {
        root: null,
        rootMargin: '0px',
        thresholds: [config.exposeMinAreaRate] // 元素出现面积，0 - 1，这里当元素出现80%以上则进行曝光
      }
    )
  }

  /**
   * 给元素添加监听
   * @param {Element} entry
   */
  add(entry) {
    this._observer && this._observer.observe(entry.el)
  }

  /**
   * 埋点上报
   */
  track() {
    const trackData = this.cacheDataArr.splice(0, this.maxNum)
    trackData.forEach((item) => {
      // qt上报的曝光类型
      const qtBuryItemExposureType = [
        '商品瀑布流-商品卡片-曝光',
        '商品详情页-精品推荐-商品卡片-曝光',
        '限时秒杀-商品卡片-曝光',
        '等价全返-商品卡片-曝光',
        '首页资源位-曝光',
        '积分中心资源位-曝光',
        '个人中心资源位-曝光',
        '商详页滑动到底部-曝光',
        '商详页顶部商品图片-曝光',
        '搜索结果-曝光',
        '订单-曝光',
        '我的订单-资源位曝光',
        '顺手买商品-曝光',
        '商品瀑布流-广告-曝光'
      ]
      const isItemIncludesQt = qtBuryItemExposureType.includes(xEnum.ENUM_RP_EVENT_TYPE.toText(item.eventType))
      if (isItemIncludesQt) {
        this.quickTrack(item)
      }
    })
    // 更新localStorage
    this.storeIntoLocalStorage(this.cacheDataArr)
  }

  // QT埋点上报
  quickTrack(item) {
    const sourceType = item.eventInfo?.eventSource
      ? item.eventInfo?.eventSource
      : xEnum.ENUM_RP_EVENT_TYPE.toText(item.eventType) ?? ''

    const epEventParams = {
      goods_score: item.eventInfo?.score,
      goods_group_type: item.eventInfo?.goodsGroupId,
      goods_name: item.eventInfo?.goodsName,
      goods_id: item.eventInfo?.goodsId,
      goods_index: item.eventInfo?.goodsIndex,
      goods_index_vertical: item.eventInfo?.goodsIndexVertical,
      display_price: item.eventInfo?.goodsPrice,
      goods_category_id: item.eventInfo?.goodsCategoryId,
      goods_source_waterfall: sourceType,
      if_bottom: item.eventInfo?.ifBottom,
      goods_recommend_source: item.eventInfo?.goodsRecommendSource,
      goods_recommend_source_goods_name: item.eventInfo?.goodsRecommendSourceGoodsName,
      recommend_swiper_index: item.eventInfo?.recommendSwipeIndex,
      place_type: item.eventInfo?.place_type,
      place_name: item.eventInfo?.place_name,
      place_addr: item.eventInfo?.place_addr,
      place_detail: item.eventInfo?.place_detail,
      module_name: item.eventInfo?.module_name,
      pic_index: item.eventInfo?.pic_index,
      expend_integral_num: item.eventInfo?.orderCostPoints,
      coupon_nm: item.eventInfo?.couponName,
      coupon_num: item.eventInfo?.couponNumber,
      order_id: item.eventInfo?.orderId,
      sku_id: item.eventInfo?.goodsSpecId,
      order_pay_status: item.eventInfo?.orderPayStatus,
      order_status: item.eventInfo?.orderStatus,
      goods_num: item.eventInfo?.goodsNum,
      if_first_order: item.eventInfo?.isFirstOrderUser,
      ssmsource_goods_id: item.eventInfo?.ssmsource_goods_id,
      ssmsource_goods_name: item.eventInfo?.ssmsource_goods_name,
      ssmsource_goods_category_id: item.eventInfo?.ssmsource_goods_category_id,
      ssmsource_goods_group_type: item.eventInfo?.ssmsource_goods_group_type,
      ssmsource_display_price: item.eventInfo?.ssmsource_display_price,
      ssmsource_display_original_price: item.eventInfo?.ssmsource_display_original_price,
      ssmsource_sku_id: item.eventInfo?.ssmsource_sku_id,
      display_original_price: item.eventInfo?.display_original_price,
      sku_name: item.eventInfo?.skuName
    }

    const eventParams = new Map([
      ['商品瀑布流-商品卡片-曝光', { eventName: 'ducuecmc_hc_waterfalls_exp', pageName: 'dacuecmc_all_page' }],
      ['商品详情页-精品推荐-商品卡片-曝光', { eventName: 'ducuecmc_hc_waterfalls_exp', pageName: 'dacuecmc_all_page' }],
      ['限时秒杀-商品卡片-曝光', { eventName: 'ducuecmc_hc_waterfalls_exp', pageName: 'dacuecmc_all_page' }],
      ['等价全返-商品卡片-曝光', { eventName: 'ducuecmc_hc_waterfalls_exp', pageName: 'dacuecmc_all_page' }],
      ['首页资源位-曝光', { eventName: 'ducuecmc_hp_place_exp', pageName: 'dacuecmc_hp_page' }],
      [
        '积分中心资源位-曝光',
        { eventName: 'ducuecmc_points_center_place_exp', pageName: 'dacuecmc_points_center_page' }
      ],
      ['个人中心资源位-曝光', { eventName: 'ducuecmc_personal_place_exp', pageName: 'dacuecmc_personal_page' }],
      [
        '商详页滑动到底部-曝光',
        { eventName: 'ducuecmc_goods_detail_bottom_exp', pageName: 'dacuecmc_goods_detail_page' }
      ],
      [
        '商详页顶部商品图片-曝光',
        { eventName: 'ducuecmc_goods_detail_picture_exp', pageName: 'dacuecmc_goods_detail_page' }
      ],
      ['搜索结果-曝光', { eventName: 'ducuecmc_hc_waterfalls_exp', pageName: 'dacuecmc_all_page' }],
      ['订单-曝光', { eventName: 'ducuecmc_myorder_order_exp', pageName: 'dacuecmc_myorder_page' }],
      ['我的订单-资源位曝光', { eventName: 'dacuecmc_myorder_place_exp', pageName: 'dacuecmc_myorder_page' }],
      ['顺手买商品-曝光', { eventName: 'ducuecmc_orderconfirm_ssmgoods_exp', pageName: 'ducuecmc_orderconfirm_page' }],
      ['商品瀑布流-广告-曝光', { eventName: 'ducuecmc_hc_waterfalls_advertise_exp', pageName: 'dacuecmc_all_page' }]
    ])
    const { eventName, pageName } = sourceType
      ? eventParams.get(xEnum.ENUM_RP_EVENT_TYPE?.toText(item.eventType))
      : {
          eventName: item.eventName,
          pageName: item.pageName
        }

    sendQuickTrackingEvent({
      eventName,
      eventType: EventType.Exp,
      pageName,
      params: {
        ...epEventParams
      }
    })
  }

  /**
   * 存储到localstorage, 防止在设定上报时间内用户退出
   * @param { Arrary } data
   */
  storeIntoLocalStorage(data) {
    try {
      window.localStorage.setItem('cacheTrackData', JSON.stringify(data))
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * 首次进入先获取localStorage中的数据，也就是用户上次退出未上报的数据
   */
  trackFromLocalStorage() {
    let cacheData = null
    try {
      cacheData = JSON.parse(window.localStorage.getItem('cacheTrackData'))
    } catch (err) {
      console.log(err)
    }
    if (cacheData && cacheData.length) {
      cacheData.forEach((item) => {
        trackUserEvent(item)
        webfunnyTrack(item)
      })
    }
  }

  /**
   * 用户退出系统时调用方法，需要和客户端同学协商注册事件
   */
  beforeLeaveWebview() {
    // 客户端自定义事件监听上报
  }
}
