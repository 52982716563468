import exapi from 'base/api/exapi'
import brand from 'base/brands'

/* eslint-disable */
const PKG_NAME = 'com.tpyx.quickapp'
const PATH = 'Home'

function initMessage(store) {
  if (!window.system) return
  window.system.onmessage = (data) => {
    let obj = null
    try {
      obj = JSON.parse(data)
      const { type, success, content } = obj
      if (!success) {
        // 如果失败，则 content 返回 {error, code}
        // alert(content.error)
      } else {
        // alert(data)
        if (type === 'hasPackage') {
          store.commit('UPDATE_APP_STATE', {
            key: 'taobaoInstalled',
            value: content.result
          })
        } else if (type === 'getUserInfo') {
          let { provider, regId, userId: h5UserId, pId } = content
          exapi.postRegInfo({ regId, h5UserId, provider, pId })
        } else if (type === 'hasShortcut') {
          store.commit('UPDATE_APP_STATE', {
            key: 'hasShortcut',
            value: !content ? true : content.result
          })
        } else if (type === 'createShortcut') {
          store.commit('UPDATE_APP_STATE', {
            key: 'hasShortcut',
            value: true
          })
        } else if (type === 'share') {
          trackEvent('确认分享', {
            action: '总数'
          })
        }
      }
    } catch (err) {
      // alert(err)
    }
  }
}

const sendMessageToQuickApp = (type, content) => {
  try {
    system.postMessage(JSON.stringify({ type, content }))
  } catch (err) {
    // console.log('不支持快应用')
  }
}

const promisefyGetQuickAppChannelReady = () => {
  return new Promise((resolve, reject) => {
    if (!window.channelReady) {
      resolve(false)
    }

    window.channelReady(
      (enable) => {
        resolve(enable)
      },
      (err) => {
        resolve(false)
      }
    )
  })
}

const saveUserIdToQuickApp = (userId) => {
  sendMessageToQuickApp('setUserId', userId)
}

let renderBrandElement = brand.renderBrandElement
let quickBrand = renderBrandElement({ id: 'global.quickBrand' })

const createQuickAppShortcut = () => {
  sendMessageToQuickApp('createShortcut', {
    disableDefaultPromot: true,
    message: `添加「${quickBrand}」到桌面，领100元优惠券>>`
  })
}

/**
 * 查看是否已创建快应用桌面图标
 */
const isShortcutInstalled = () => {
  sendMessageToQuickApp('hasShortcut')
}

const trackEvent = (id, attr) => {
  sendMessageToQuickApp('trackEvent', { id, attr })
}

const pickContact = () => {
  sendMessageToQuickApp('pickContact')
}

const listContact = () => {
  sendMessageToQuickApp('listContact')
}

const sendSms = (address, content) => {
  sendMessageToQuickApp('sendSms', {
    address,
    content
  })
}

const openWebview = (url) => {
  sendMessageToQuickApp('openWebview', {
    url
  })
}

/**
 *
 * @param {*} type 'text/html'
 * @param {*} data '<p>test</p>'
 */
const share = (type, data) => {
  sendMessageToQuickApp('share', {
    type,
    data
  })
}

const saveToStorage = (key, value) => {
  sendMessageToQuickApp('saveToStorage', {
    key,
    value
  })
}

const getFromStorage = (key) => {
  sendMessageToQuickApp('getFromStorage', {
    key
  })
}

const getUserInfo = () => {
  sendMessageToQuickApp('getUserInfo')
}

const hasPackage = (packageName) => {
  sendMessageToQuickApp('hasPackage', {
    packageName
  })
}

const routerPush = (router) => {
  sendMessageToQuickApp('routerPush', router)
}

const installPackage = (packageName) => {
  sendMessageToQuickApp('installPackage', {
    packageName
  })
}

export default {
  initMessage,
  saveUserIdToQuickApp,
  createQuickAppShortcut,
  promisefyGetQuickAppChannelReady,
  trackEvent,
  pickContact,
  listContact,
  sendSms,
  openWebview,
  share,
  saveToStorage,
  getFromStorage,
  getUserInfo,
  isShortcutInstalled,
  hasPackage,
  routerPush,
  installPackage
}
