import _ from 'lodash'
import request from 'base/utils/request'
import { trackEvent } from 'base/utils/rp'
import utils from 'base/utils'
import { Toast, Dialog } from 'vant'

export function wxLogin(retryTimes = 2) {
  if (!utils.isInWeixn()) return
  if (sessionStorage.getItem('openid')) return

  const openid = utils.getUrlParam('openid')
  if (openid) {
    sessionStorage.setItem('openid', openid)
    return
  }

  request({
    url: '/wxPay/getAppId',
    method: 'get'
  })
    .then((response) => {
      const appId = response.data
      const redirectUri = encodeURIComponent(`${process.env.VUE_APP_BASE_API}/wxPay/token?appId=${appId}`)
      const state = btoa(encodeURIComponent(window.location.href))
      window.location.href =
        `https://open.weixin.qq.com/connect/oauth2/authorize?` +
        `appid=${appId}&` +
        `redirect_uri=${redirectUri}&` +
        `response_type=code&` +
        `scope=snsapi_base&` +
        `state=${state}&` +
        `#wechat_redirect`
    })
    .catch(() => {
      retryTimes = retryTimes - 1
      if (retryTimes === 0) {
        alert('网络请求错误,请重试')
      } else {
        wxLogin(retryTimes)
      }
    })
}
// 存储微信公众号拉起支付时支付参数
let paramsOfOrderId = ''
let paramsOfpId = ''
let paramsOfUserId = ''
function onBridgeReady(payParams) {
  trackEvent({
    category: `提交订单-微信-微信内`,
    action: '拉起支付_onBridgeReady',
    opt_label: `总数`
  })
  return new Promise((resolve, reject) => {
    window.WeixinJSBridge.invoke('getBrandWCPayRequest', payParams, function (res) {
      trackEvent({
        category: `提交订单-微信-微信内`,
        action: '拉起支付_onBridgeReady',
        opt_label: `结果总数`
      })
      if (res.err_msg == 'get_brand_wcpay_request:ok') {
        trackEvent({
          category: `提交订单-微信-微信内`,
          action: '拉起支付_onBridgeReady',
          opt_label: `成功总数`
        })
        resolve()
      } else if (res.err_msg == 'get_brand_wcpay_request:cancel') {
        trackEvent({
          category: `提交订单-微信-微信内`,
          action: '拉起支付_onBridgeReady',
          opt_label: `取消总数`
        })
        // Toast.fail('支付取消')
        // reject('支付取消')
        Dialog.confirm({
          title: '温馨提示',
          className: 'pay-cancel-dialog',
          message: `<div style="font-size: 18px; color: #000000">支付失败，是否重新支付</div>`,
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          confirmButtonColor: '#1AAD19',
          cancelButtonColor: '#AAAAAA',
          allowHtml: true
        })
          .then(() => {
            // on confirm 再次拉起支付
            wxPubPay(paramsOfOrderId, paramsOfpId, paramsOfUserId)
            const dialogDom = document.querySelector('.pay-cancel-dialog')
            dialogDom.remove()
            return
          })
          .catch(() => {
            // on cancel
            reject('支付取消')
            const dialogDom = document.querySelector('.pay-cancel-dialog')
            dialogDom.remove()
            return
          })
        // 当用户不在两个按钮中选择，而直接再次返回
        const dialogDom = document.querySelector('.pay-cancel-dialog')
        if (dialogDom) {
          dialogDom.remove()
        }
      } else if (res.err_msg == 'get_brand_wcpay_request:fail') {
        trackEvent({
          category: `提交订单-微信-微信内`,
          action: '拉起支付_onBridgeReady',
          opt_label: `失败总数`
        })
        Toast.fail('支付失败')
        reject('支付失败')
      } //使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回  ok，但并不保证它绝对可靠。
      else {
        trackEvent({
          category: `提交订单-微信-微信内`,
          action: '拉起支付_onBridgeReady',
          opt_label: `未知结果`
        })
        reject('未知结果')
      }
    })
  })
}

function callJsApiPay(payParams) {
  trackEvent({
    category: `提交订单-微信-微信内`,
    action: '拉起支付-开始'
    // opt_label: `支付方式${this.radio}`,
    // opt_value: good.price
  })
  // 尚未初始化好，则需要挂载个回调 onReady。
  if (typeof WeixinJSBridge == 'undefined') {
    return new Promise((resolve, reject) => {
      const onReady = async () => {
        try {
          const res = await onBridgeReady(payParams)
          resolve(res)
        } catch (error) {
          reject(error)
        }
      }
      if (document.addEventListener) {
        document.addEventListener('WeixinJSBridgeReady', onReady, false)
      } else if (document.attachEvent) {
        document.attachEvent('WeixinJSBridgeReady', onReady)
        document.attachEvent('onWeixinJSBridgeReady', onReady)
      }
    })
  } else {
    trackEvent({
      category: `提交订单-微信-微信内`,
      action: '拉起支付_onBridgeReady'
    })
    return onBridgeReady(payParams)
  }
}

export function wxPubPay(orderId, pId, userId) {
  paramsOfOrderId = orderId
  paramsOfpId = pId
  paramsOfUserId = userId
  trackEvent({
    category: `提交订单-微信-微信内`,
    action: '总数'
    // opt_label: `支付方式${this.radio}`,
    // opt_value: good.price
  })

  // 为了兼容老版本用户，仍然从sessionStorage中获取openid
  const openid = sessionStorage.getItem('openid') || localStorage.getItem('openid')
  return new Promise((resolve, reject) => {
    request({
      url: '/wxPay/wxOfficalPay',
      method: 'post',
      params: { orderId, openid, pId, userId }
    })
      .then((response) => {
        trackEvent({
          category: `提交订单-微信-微信内`,
          action: '支付参数-有响应'
        })
        if (response.code === 200) {
          trackEvent({
            category: `提交订单-微信-微信内`,
            action: '支付参数-成功'
          })
          const payParams = _.pick(response.data, ['appId', 'paySign', 'timeStamp', 'nonceStr', 'package', 'signType'])
          callJsApiPay(payParams)
        } else {
          trackEvent({
            category: `提交订单-微信-微信内`,
            action: '支付参数-失败',
            opt_label: response.message
            // opt_value: good.price
          })
          // Toast.fail(response.message)
        }
        resolve(response)
      })
      .catch((error) => {
        reject(error)
        Toast.fail(error)
      })
  })
}

export function wxH5Pay(params) {
  trackEvent({
    category: `提交订单-微信-外部浏览器`,
    action: '总数'
    // opt_label: `支付方式${this.radio}`,
    // opt_value: good.price
  })
  return request({
    url: '/wxPay/h5Pay',
    method: 'post',
    params
  })
}

export function wxH5MergePay(data) {
  return request({
    url: '/wxPay/merge/h5Pay',
    method: 'post',
    data
  })
}

export default {
  wxLogin,
  wxH5Pay,
  wxH5MergePay,
  wxPubPay
}
