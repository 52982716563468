import { saveOrder } from 'base/api'
import { sendQuickTrackingEvent, EventType } from './quickTracking'
import { trackEvent } from 'base/utils/rp'
import { Toast } from 'vant'
import store from '@/store'
import Cookies from 'js-cookie'

export async function goodsSaveOrder(orderApiParams, orderParams, qtParams) {
  try {
    const response = await saveOrder(orderApiParams)
    if (response.code === 200) {
      trackEvent({
        category: `下单-提交流程`,
        action: 'saveOrder成功'
      })

      // 生成订单成功埋点上报
      sendQuickTrackingEvent({
        eventName: 'ducuecmc_goods_detail_order_suc',
        eventType: EventType.Other,
        pageName: 'dacuecmc_goods_detail_page',
        params: {
          ...qtParams,
          order_id: response.data.orderId
        }
      })

      Cookies.set('receiverName', orderApiParams.receiverName, { expires: 30 })
      Cookies.set('phone', orderApiParams.phone, { expires: 30 })
      Cookies.set('addressProvince', orderApiParams.addressProvince, { expires: 30 })
      Cookies.set('addressCity', orderApiParams.addressCity, { expires: 30 })
      Cookies.set('addressArea', orderApiParams.addressArea, { expires: 30 })
      Cookies.set('addressDetail', orderApiParams.addressDetail, { expires: 30 })
      if (orderApiParams.addressCode) {
        Cookies.set('addressCode', orderApiParams.addressCode, { expires: 30 })
      }

      return response
    } else if (response.code === 405 || response.code === 5202) {
      // 下单地区已屏蔽或提醒 要求用户重新填写地址
      Toast.fail({
        message: response.message,
        duration: 4000
      })

      // 生成订单失败
      sendQuickTrackingEvent({
        eventName: 'ducuecmc_goods_detail_order_fail',
        eventType: EventType.Other,
        pageName: 'dacuecmc_goods_detail_page',
        params: {
          ...qtParams,
          false_reason: response.message
        }
      })
      return response
    } else {
      Toast.fail('下单失败：' + response.message)

      trackEvent({
        category: `下单-提交流程`,
        action: 'saveOrder失败',
        opt_label: response.message
      })
      // 生成订单失败
      sendQuickTrackingEvent({
        eventName: 'ducuecmc_goods_detail_order_fail',
        eventType: EventType.Other,
        pageName: 'dacuecmc_goods_detail_page',
        params: {
          ...qtParams,
          false_reason: response.message
        }
      })
      return
    }
  } catch (err) {
    Toast.fail('网络超时，请稍后再试')
    trackEvent({
      category: `下单-提交流程`,
      action: 'saveOrder失败',
      opt_label: `用户ID${store.state.user.userId}`
    })

    // 生成订单失败
    sendQuickTrackingEvent({
      eventName: 'ducuecmc_goods_detail_order_fail',
      eventType: EventType.Other,
      pageName: 'dacuecmc_goods_detail_page',
      params: {
        ...qtParams,
        false_reason: '网络超时，请稍后再试'
      }
    })
  }
}
