// /**
//  * 工具方法-微信jssdk
//  * 此文件是适配的 history api 的方式，但是 hashbang 的方式应该也会兼容
//  */
// import wx from 'wx'
import { getJssdkConfig } from '../api'
import store from '@/store'

/**
 * 微信签名需要区分手机
 * IOS：微信IOS版，每次切换路由，SPA的url是不会变的，发起签名请求的url参数必须是当前页面的url就是最初进入页面时的url
 * Android：微信安卓版，每次切换路由，SPA的url是会变的，发起签名请求的url参数必须是当前页面的url(不是最初进入页面时的)
 */
const location = window.location
let entryUrl = location.href
if (location.hash.length) {
  // 如果location是带#锚点的，则截取掉#锚点后的部分。
  entryUrl = entryUrl.substr(0, entryUrl.indexOf(location.hash))
}
window.entryUrl = entryUrl
const isiOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)

export function initWechatJs() {
  const hm2 = document.createElement('script')
  hm2.src = 'https://res.wx.qq.com/open/js/jweixin-1.6.0.js'
  const s2 = document.getElementsByTagName('script')[0]
  s2.parentNode.insertBefore(hm2, s2)
}

function getCurrentUrl() {
  let currentUrl
  if (isiOS) {
    currentUrl = entryUrl
  } else {
    const location = window.location
    currentUrl = location.href
    if (location.hash.length) {
      // 如果location是带#锚点的，则截取掉#锚点后的部分。
      currentUrl = currentUrl.substr(0, currentUrl.indexOf(location.hash))
    }
  }
  return currentUrl
}

async function initJssdk(apiList) {
  const wx = window.wx
  if (!wx) return
  const isInWechat = store.state.appState.isInWechat
  if (process.env.NODE_ENV === 'development' || !isInWechat) return

  const currentUrl = getCurrentUrl()
  // 从server获取必要的数据用于初始化
  const resp = await getJssdkConfig(currentUrl)
  const signature = resp.data
  wx.config({
    debug: process.env.NODE_ENV === 'development',
    // debug: true,
    appId: signature.appId,
    timestamp: signature.timestamp,
    nonceStr: signature.nonceStr,
    signature: signature.signature,
    jsApiList: apiList
  })

  return new Promise((resolve, reject) => {
    wx.ready(function (res) {
      resolve(res)
    })
    wx.error(function (res) {
      store.commit('UPDATE_APP_STATE', {
        key: 'wechatSdkReject',
        value: false
      })
      reject(res)
      return
    })
  })
}

function checkJssdk(apiList) {
  const wx = window.wx
  if (!wx) return
  return new Promise((resolve, reject) => {
    if (process.env.WX_DEBUG) {
      resolve()
      return
    }
    wx.checkJsApi({
      // 重要：此处必须传给微信一个副本，否则微信会破坏参数apiList中的值。previewImage会变为imagePreview
      jsApiList: JSON.parse(JSON.stringify(apiList)),
      success: function (res) {
        // 以键值对的形式返回，可用的api值true，不可用为false
        // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
        // apiList有任何一个检查为false，则为invalid。
        const invalid = _.some(apiList, (apiName) => !res.checkResult[apiName])
        if (invalid) {
          window.alert('对不起，您的微信不支持微信高级功能!')
          reject()
        } else {
          resolve()
        }
      }
    })
  })
}

export function openAddress() {
  const wx = window.wx
  if (!wx) return
  const isInWechat = store.state.appState.isInWechat
  if (process.env.NODE_ENV === 'development' || !isInWechat) return
  return new Promise((resolve, reject) => {
    wx.openAddress({
      success: function (res) {
        // 用户成功导入地址
        resolve(res)
      },
      cancel: function (res) {
        // 用户取消导入地址
        reject(res)
      }
    })
  })
}

export default {
  initWechatJs,
  initJssdk,
  checkJssdk,
  openAddress
}
