/* eslint-disable camelcase */
import store from '@/store'
import xEnum from 'base/utils/enum'
import utils from 'base/utils'

export const EventType = {
  Exp: 'EXP',
  Click: 'CLK',
  Other: 'OTHER',
  Pv: 'PV'
}

const qtFlag = true

function getUserGlobalParams() {
  if (!qtFlag) return
  const currentSourceEnum = Number(process.env.VUE_APP_SOURCE_ENUM)
  const sourceXEnum = _.find(xEnum.ENUM_DATA_REPORT_SOURCES, (item) => {
    return item.value === currentSourceEnum
  })
  //创建用户唯一会话id
  let userSessionId = ''
  if (window.sessionStorage.getItem('userSessionId')) {
    userSessionId = window.sessionStorage.getItem('userSessionId')
  } else {
    userSessionId = utils.getSessionId()
    window.sessionStorage.setItem('userSessionId', userSessionId)
  }
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const paramsObj = {}
  urlParams.forEach((value, key) => {
    paramsObj[key] = value
  })

  // 解决微信浏览器环境中pId后接?现象
  const pId = paramsObj.pId
    ? paramsObj.pId[paramsObj.pId.length - 1] === '?'
      ? paramsObj.pId.slice(0, paramsObj.pId.length - 1)
      : paramsObj.pId
    : ''
  const userGlobalParams = {
    project_id: pId || store.state.appState.pId,
    user_id: store.state.user.userId || localStorage.getItem('userId'),
    web_url: window.location.href,
    point: store.state.user.points.activePoints,
    source: sourceXEnum.value,
    app_type: store.state.appState.appType,
    phone: store.state.user.phone || '',
    app_branch_type: 'A',
    project_name: store.state.appState.projectName,
    user_session_id: userSessionId
  }

  if (store.state.appState.appType == xEnum.ENUM_APP_TYPE.H5_IN_ALI_MP.getValue()) {
    userGlobalParams.alipayAppId = store.state.appState.alipayAppId
  }
  return userGlobalParams
}

// 事件日志
export function sendQuickTrackingEvent({ eventName, eventType, params }) {
  const { aliTracker } = window
  if (!aliTracker) return
  const userGlobalParams = getUserGlobalParams()
  const regex = /\/vantmall(.*)/
  const result = regex.exec(window.location.pathname)
  let eventPagePath = ''
  if (result) {
    eventPagePath = result[1].includes('goodsProfile') ? '/goodsProfile' : result[1]
  }
  const forwardPathParams = store.state.appState.forwardPathParams

  aliTracker.sendImmediate({
    eventName,
    eventType,
    gp: {
      event_page_path: eventPagePath,
      ...userGlobalParams,
      ...forwardPathParams
    }, // 全局属性
    cusp: {
      ...params,
      page_name: qtPageMap?.get(eventPagePath) || 'unknown'
    }, // 自定义属性
    user_agent: navigator.userAgent,
    timestamp: new Date().getTime()
  })
}

// 页面日志
export function sendPageTrackEvent(pagePath, forwardPathParams, toPath) {
  const { aliTracker } = window
  if (!aliTracker) return

  const purePath = utils.extractPathAndId(pagePath)
  const pageName = qtPageMap?.get(purePath) || 'unknown'
  const userGlobalParams = getUserGlobalParams()
  const currentWebUrl = window.location.origin + '/vantmall' + toPath

  const globalParams = {
    event_page_path: purePath,
    ...userGlobalParams,
    web_url: currentWebUrl
  }

  const pvParams = {
    pageName: pageName,
    eventName: pageName,
    user_agent: navigator.userAgent,
    timestamp: new Date().getTime()
  }

  if (store.state.user.userId) {
    aliTracker.sendImmediate({
      eventType: EventType.Pv,
      gp: { ...globalParams, ...forwardPathParams }, // 全局属性
      ...pvParams
    })
  } else {
    store.state.appState.cacheQtEvents.push({
      globalParams,
      pvParams
    })
  }
}

const qtPageMap = new Map([
  ['/complain', 'dacuecmc_complaint_page'],
  ['/leftFirst', 'dacuecmc_crazy_rank_page'],
  ['/activity/brand', 'dacuecmc_event_page'],
  ['/activity/returnScore', 'dacuecmc_full_return_page'],
  ['/goodsProfile', 'dacuecmc_goods_detail_page'],
  ['/home', 'dacuecmc_hp_page'],
  ['/activity/timeLimit', 'dacuecmc_limited_time_flash_sale_page'],
  ['/activity/timeLimit', 'dacuecmc_limited_time_flash_sale_page'],
  ['/express', 'dacuecmc_logistics_page'],
  ['/myorder3', 'dacuecmc_myorder_page'],
  ['/customService1', 'dacuecmc_ocsp_page'],
  ['/payindex', 'dacuecmc_orderconfirm_page'],
  ['/ordersuccess', 'dacuecmc_payment_suc_page'],
  ['/mine', 'dacuecmc_personal_page'],
  ['/userInfo', 'dacuecmc_personal_page'],
  ['/primary1', 'dacuecmc_points_center_page'],
  ['/protocol', 'dacuecmc_privacy_policy_page'],
  ['/goodsSearch', 'dacuecmc_search_page'],
  ['/grantPhone', 'dacuecmc_authorization_page'],
  ['/centerPageA', 'dacuecmc_push_flow_page'],
  ['/centerPageB', 'dacuecmc_push_flow_page'],
  ['/fillRefundNumber', 'dacuecmc_fill_refund_number_page'],
  ['/serviceOrdersDetail', 'dacuecmc_service_orders_detail_page'],
  ['/cooperation', 'dacuecmc_cooperation_page'],
  ['/comment', 'dacuecmc_comment_page']
])

export const sendCacheQtEvents = (userId) => {
  const { aliTracker } = window
  if (!aliTracker) return
  const userGlobalParams = getUserGlobalParams()
  store.state.appState.cacheQtEvents.forEach((event) => {
    const { globalParams, pvParams } = event

    aliTracker.sendImmediate({
      eventType: EventType.Pv,
      gp: { ...globalParams, ...userGlobalParams, user_id: userId }, // 全局属性
      ...pvParams
    })
  })
  store.state.appState.cacheQtEvents = []
}

export default {
  sendQuickTrackingEvent,
  sendPageTrackEvent,
  sendCacheQtEvents,
  qtPageMap
}
